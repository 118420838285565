// This is essentially a custom version of https://github.com/egoist/style-inject
// with this issue fixed: https://github.com/egoist/style-inject/issues/23
//
// NOTE: because this file is not imported by any component in our build process,
// it is not transpiled by typescript, etc, so it is written in es5

export default function styleInject(css) {
  if (!css || typeof document === 'undefined') return; // allow turning off injection if only using criticalCss / SSR

  if (window.injectThreadsStyles === false) return;
  var INSERT_BEFORE_ID = 'threads-style-inject-insert-before';
  var head = document.head || document.getElementsByTagName('head')[0]; // an empty style tag that is inserted at the top of the head and which all
  // further styles are inserted before. This fixes the reverse insertion issue
  // reported in https://github.com/egoist/style-inject/issues/23

  var elementToInsertBefore = document.getElementById(INSERT_BEFORE_ID); // if this is the first injected style, we must create the marker element
  // that we will use for all further injections

  if (elementToInsertBefore == null) {
    elementToInsertBefore = document.createElement('style');
    elementToInsertBefore.id = INSERT_BEFORE_ID; // if there is already stuff in the head, put this first

    if (head.firstChild) {
      head.insertBefore(elementToInsertBefore, head.firstChild);
    } else {
      // if the head is empty, just insert this
      head.appendChild(elementToInsertBefore);
    }
  }

  var style = document.createElement('style');
  style.type = 'text/css';
  head.insertBefore(style, elementToInsertBefore);

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}
